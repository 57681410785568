import React from "react";
import Container from "./Container";

export default () => (
  <Container>
    <h3 className="text-lg leading-6 font-medium text-gray-800 mb-4">
      Hai un'attività che consegna a domicilio a Roma?
    </h3>
    <p className="mt-1 text-sm leading-5 text-gray-500 mb-4">
      Se sei un negoziante o hai un'attività che fa consegne a domicilio, registati e comincia a vendere su TrovaSpesa.
      Il servizio è gratuito per tutta la durata della quarantena.
    </p>
    <p className="mt-1 text-sm leading-5 text-gray-500 mb-4">
      Più di 1.000 persone al giorno, a Roma, comprano su TrovaSpesa.
    </p>
    <span className="inline-flex rounded-md shadow-sm w-full sm:w-auto">
      <div
        onClick={() => {
          window.analytics.track("Exit on Signup");
          window.open("/signup");
        }}
        className="w-full sm:w-auto justify-center inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:border-green-300 focus:shadow-outline-green active:text-gray-800 active:bg-green-100 transition ease-in-out duration-150"
      >
        <svg className="-ml-0.5 mr-2 h-4 w-4" fill="#242F3E" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
            clipule="evenodd"
          />
        </svg>
        Registrati
      </div>
    </span>
  </Container>
);
