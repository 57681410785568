import React from "react";

export default () => (
  <div className="bg-green-700">
    <div className="pt-8 px-4 sm:px-6 lg:px-8 lg:pt-8">
      <div className="flex flex-col items-center text-center">
        {/* <a href="/">
        <img alt="logo" className="h-7 w-auto" src="https://p5.f1.n0.cdn.getcloudapp.com/items/v1urZb91/logo.png"/>
      </a> */}
        <h2 className="mt-3 sm:mt-15 leading-6 font-semibold text-green-100 uppercase tracking-wider">
          A roma più di 10.000 persone hanno già comprato su TrovaSpesa
        </h2>
        <p className="mt-3 text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
          Aumenta le tue consegne a domicilio, gratis.
        </p>
        <p className="mt-5 max-w-4xl mx-auto text-xl leading-7 text-green-100 sm:mt-10 sm:text-2xl sm:leading-8">
          Lavoriamo per{" "}
          <span
            className="font-medium text-white px-1"
            style={{ "background-image": "linear-gradient(180deg,transparent 65%,rgba(237, 137, 54, 0.7) 0)" }}
          >
            portarti più clienti
          </span>{" "}
          in questo momento difficile. Comincia a vendere oggi su TrovaSpesa.
        </p>
        <p className="mt-3 px-2 text-center mt-6 max-w-4xl mx-auto text-xl text-green-100 sm:mt-10 sm:text-2xl sm:leading-8">
          Inserisci il codice sconto "COVID": il servizio sarà{" "}
          <span
            className="px-1 font-medium text-white"
            style={{ "background-image": "linear-gradient(180deg,transparent 65%,#48BB78 0)" }}
          >
            gratis
          </span>{" "}
          per i primi{" "}
          <span
            className="px-1 text-white font-medium"
            style={{ "background-image": "linear-gradient(180deg,transparent 65%,#48BB78 0)" }}
          >
            30 giorni
          </span>
          , e potrai disiscriverti in qualunque momento.
        </p>
      </div>
    </div>

    <div className="mt-10 bg-white pb-12 sm:mt-20 lg:pb-20">
      <div className="relative z-0">
        <div className="absolute inset-0 h-5/6 bg-green-700 lg:h-2/3"></div>
        <div className="max-w-screen-xl mx-auto px-4 pb-4 sm:px-6 lg:px-8">
          <div className="relative lg:grid lg:grid-cols-7">
            <div className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
              <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                <div className="flex-1 flex flex-col">
                  <div className="bg-white px-6 py-10">
                    <div>
                      <h2 className="text-center text-2xl leading-8 font-medium text-gray-900">Singolo quartiere</h2>
                      <div className="mt-4 flex items-center justify-center">
                        <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                          <span className="mt-2 mr-2 text-4xl font-medium">€</span>
                          <span className="font-extrabold">25</span>
                        </span>
                        <span className="text-xl leading-7 font-medium text-gray-400">/mese</span>
                      </div>
                      <p className="mt-4 text-xs text-gray-400 text-center">
                        Gratis per 30 giorni inserendo il codice sconto "COVID"
                      </p>
                    </div>
                  </div>
                  <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                    <ul>
                      <li className="flex items-start">
                        <div className="flex-shrink-0">
                          <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">Visibilità tra i risultati</p>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                          <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">Fino a 3 CAP serviti</p>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                          <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">Statistiche mensili</p>
                      </li>
                    </ul>
                    <div className="mt-8">
                      <div className="rounded-lg shadow-md">
                        <a
                          href="#/ms/signup/5e9f5b20f897f80017292c1c"
                          className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-orange-500 hover:text-orange-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                        >
                          Comincia subito
                        </a>
                      </div>
                      <p className="mt-2 text-center text-xs text-gray-400">Senza impegno, cancella quando vuoi</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
              <div className="relative z-10 rounded-lg shadow-xl">
                <div className="pointer-events-none absolute inset-0 rounded-lg border-4 border-orange-500"></div>
                <div className="absolute inset-x-0 top-0 transform translate-y-px">
                  <div className="flex justify-center transform -translate-y-1/2">
                    <span className="inline-flex rounded-full bg-orange-500 px-4 py-1 text-sm leading-5 font-semibold tracking-wider uppercase text-white">
                      Più popolare
                    </span>
                  </div>
                </div>
                <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">
                  <div>
                    <h2 className="text-center text-3xl leading-9 font-semibold text-gray-900 sm:-mx-6">
                      Multi-quartiere
                    </h2>
                    <div className="mt-4 flex items-center justify-center">
                      <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900 sm:text-6xl">
                        <span className="mt-2 mr-2 text-4xl font-medium">€</span>
                        <span className="font-extrabold">50</span>
                      </span>
                      <span className="text-2xl leading-8 font-medium text-gray-400">/mese</span>
                    </div>
                    <p className="mt-4 text-xs text-gray-400 text-center">
                      Gratis per 30 giorni inserendo il codice sconto "COVID"
                    </p>
                  </div>
                </div>
                <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                  <ul>
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p className="ml-3 text-base leading-6 font-medium text-gray-500">Visibilità tra i risultati</p>
                    </li>
                    <li className="mt-4 flex items-start">
                      <div className="flex-shrink-0">
                        <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p className="ml-3 text-base leading-6 font-medium text-gray-500">Fino a 10 CAP serviti</p>
                    </li>
                    <li className="mt-4 flex items-start">
                      <div className="flex-shrink-0">
                        <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p className="ml-3 text-base leading-6 font-medium text-gray-500">Statistiche mensili avanzate</p>
                    </li>
                    <li className="mt-4 flex items-start">
                      <div className="flex-shrink-0">
                        <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p className="ml-3 text-base leading-6 font-medium text-gray-500">Possibilità di caricare foto</p>
                    </li>
                  </ul>
                  <div className="mt-10">
                    <div className="rounded-lg shadow-md">
                      <a
                        href="#/ms/signup/5ea008abf897f80017293822"
                        className="block w-full text-center rounded-lg border border-transparent bg-orange-500 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange transition ease-in-out duration-150"
                      >
                        Comincia subito
                      </a>
                    </div>
                    <p className="mt-2 text-center text-xs text-gray-400">Senza impegno, cancella quando vuoi</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
              <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">
                <div className="flex-1 flex flex-col">
                  <div className="bg-white px-6 py-10">
                    <div>
                      <h2 className="text-center text-2xl leading-8 font-medium text-gray-900">Intera città</h2>
                      <div className="mt-4 flex items-center justify-center">
                        <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                          <span className="mt-2 mr-2 text-4xl font-medium">€</span>
                          <span className="font-extrabold">99</span>
                        </span>
                        <span className="text-xl leading-7 font-medium text-gray-400">/mese</span>
                      </div>
                      <p className="mt-4 text-xs text-gray-400 text-center">
                        Gratis per 30 giorni inserendo il codice sconto "COVID"
                      </p>
                    </div>
                  </div>
                  <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                    <ul>
                      <li className="flex items-start">
                        <div className="flex-shrink-0">
                          <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">Numero illimitato di CAP</p>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                          <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">Servizio personalizzato</p>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                          <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">Assistenza dedicata 24/7</p>
                      </li>
                    </ul>
                    <div className="mt-8">
                      <div className="rounded-lg shadow-md">
                        <a
                          href="#/ms/signup/5ea008cb53c95d0017f36fff"
                          className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-orange-500 hover:text-orange-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                        >
                          Comincia subito
                        </a>
                      </div>
                      <p className="mt-2 text-center text-xs text-gray-400">Senza impegno, cancella quando vuoi</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
