import React from "react";

export default () => (
  <section className="py-12 min-h-screen bg-gray-50 overflow-hidden md:py-20 lg:py-24">
    <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="relative">
        <p className="mx-auto text-center text-6xl">🎉</p>

        <blockquote className="mt-8 flex flex-col items-center">
          <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
            <p className="text-green-500 text-3xl font-semibold">Benvenuto e grazie per esserti registrato!</p>
            <p className="mt-5 text-gray-600">
              Ti contatterò nelle prossime ore per conoscerci, raccogliere le tue aspettative e cominciare a lavorare
              insieme!
            </p>
          </div>
          <footer className="mt-8">
            <div className="md:flex md:items-center md:justify-center">
              <div className="md:flex-shrink-0">
                <img
                  className="mx-auto h-10 w-10 rounded-full"
                  src="https://p5.f1.n0.cdn.getcloudapp.com/items/d5uv0pDn/tyba3a-min.jpg"
                  alt=""
                />
              </div>
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-900">Luca Rossi</div>

                <svg className="hidden md:block mx-1 h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M11 0h3L9 20H6l5-20z" />
                </svg>

                <div className="text-base leading-6 font-medium text-gray-500">luca@trovaspesa.info</div>
              </div>
            </div>
          </footer>
          <span className="mt-15 sm:mt-20 inline-flex rounded-md shadow-sm">
            <a
              href="/"
              className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-500 hover:bg-green-400 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition ease-in-out duration-150"
            >
              <svg className="-ml-1 mr-2 h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                <path
                  className="heroicon-ui"
                  d="M13 20v-5h-2v5a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-7.59l-.3.3a1 1 0 1 1-1.4-1.42l9-9a1 1 0 0 1 1.4 0l9 9a1 1 0 0 1-1.4 1.42l-.3-.3V20a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2zm5 0v-9.59l-6-6-6 6V20h3v-5c0-1.1.9-2 2-2h2a2 2 0 0 1 2 2v5h3z"
                />
              </svg>
              Torna alla home
            </a>
          </span>
        </blockquote>
      </div>
    </div>
  </section>
);
