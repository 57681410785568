import React from "react";
import { Animate } from "react-simple-animate";

export default ({ children, play }) => (
  <Animate
    play={play}
    duration={0.3}
    start={{ opacity: 0, transform: "translateY(-10px)" }}
    end={{ opacity: 1, transform: "translateY(0px)" }}
  >
    {children}
  </Animate>
);
