import React from "react";
import Container from "./Container";

const SUPPLIER_LOGOS = [
  "/crai-min.png",
  "/vallesanta-min.png",
  "/easycoop-min.png",
  "/carrefour-essentials-min.jpg",
  "/pursudtirol-min.png",
  "/biola-min.jpg",
  "/castoro-min.png",
  "/lattetrento-min.png",
];

export default () => (
  <Container>
    <div className="flex flex-col">
      <div className="text-left mb-5">
        <h3 className="text-lg font-medium text-gray-900">Come funziona?</h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Confrontiamo in tempo reale i servizi di spesa a domicilio nella zona di Roma e provincia, e troviamo la
            prima data disponibile in cui possono consegnarti la spesa.
          </p>
        </div>
      </div>
      <div className="flex flex-wrap mb-1 justify-center">
        {SUPPLIER_LOGOS.map((logoUrl, i) => (
          <img
            key={i}
            className="h-5 sm:h-8 mx-3 mb-2 opacity-50 hover:opacity-75 last:pr-0"
            alt="Logo"
            src={logoUrl}
          />
        ))}
      </div>
    </div>
  </Container>
);
