import React from "react";

export default () => (
  <nav className="bg-green-500 shadow">
    <div className="flex-1 flex items-center justify-between max-w-2xl mx-auto px-4 sm:px-6 h-16">
      <img alt="logo" className="h-7 w-auto" src="https://p5.f1.n0.cdn.getcloudapp.com/items/v1urZb91/logo.png" />
      <a
        className="text-sm hidden sm:block sm:text-sm text-green-50 hover:underline"
        data-ms-content="!members"
        href="#/ms/login"
      >
        Sei un partner registrato? Accedi qui
      </a>
      <a
        className="text-sm block sm:hidden sm:text-sm text-green-50 hover:underline"
        data-ms-content="!members"
        href="#/ms/login"
      >
        Accedi
      </a>
      <a className="text-sm text-white" data-ms-content="members" href="#/ms/profile">
        Il tuo profilo
      </a>
    </div>
  </nav>
);
