import React from "react";

const Container = ({ children, fill, size, className }) => (
  <div className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 172 172"
      style={{ fill: fill }}
    >
      {children}
    </svg>
  </div>
);
export const Truck = ({ fill = "#000000", size = 24, className }) => (
  <Container fill={fill} size={size} className={className}>
    <g fill="none" fillRule="nonzero" stroke="none">
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill={fill}>
        <path d="M17.2,28.66667c-6.33533,0 -11.46667,5.13133 -11.46667,11.46667v80.26667c-2.06765,-0.02924 -3.99087,1.05709 -5.03322,2.843c-1.04236,1.78592 -1.04236,3.99474 0,5.78066c1.04236,1.78592 2.96558,2.87225 5.03322,2.843h11.46667h6.02448c1.4184,9.66302 9.74257,17.2 19.77552,17.2c10.03295,0 18.35712,-7.53698 19.77552,-17.2h40.42448h11.75781c1.4184,9.66302 9.74257,17.2 19.77552,17.2c10.03295,0 18.35712,-7.53698 19.77552,-17.2h11.75781c2.06765,0.02924 3.99087,-1.05709 5.03322,-2.843c1.04236,-1.78592 1.04236,-3.99474 0,-5.78066c-1.04236,-1.78592 -2.96558,-2.87225 -5.03322,-2.843v-28.66667h-34.4v-22.93333c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333h16.38255l-1.93724,-4.51276c-1.806,-4.214 -5.95057,-6.95391 -10.53724,-6.95391h-38.30808v-11.46667c0,-6.33533 -5.13133,-11.46667 -11.46667,-11.46667zM43,120.4c4.81756,0 8.6,3.78244 8.6,8.6c0,4.81756 -3.78244,8.6 -8.6,8.6c-4.81756,0 -8.6,-3.78244 -8.6,-8.6c0,-4.81756 3.78244,-8.6 8.6,-8.6zM134.73333,120.4c4.81756,0 8.6,3.78244 8.6,8.6c0,4.81756 -3.78244,8.6 -8.6,8.6c-4.81756,0 -8.6,-3.78244 -8.6,-8.6c0,-4.81756 3.78244,-8.6 8.6,-8.6z"></path>
      </g>
    </g>
  </Container>
);

export const Cart = ({ fill = "#000000", size = 24, className }) => (
  <Container fill={fill} size={size} className={className}>
    <g fill="none" fillRule="nonzero" stroke="none">
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill={fill}>
        <path d="M11.46667,11.46667c-2.06765,-0.02924 -3.99087,1.05709 -5.03322,2.843c-1.04236,1.78592 -1.04236,3.99474 0,5.78066c1.04236,1.78592 2.96558,2.87225 5.03322,2.843h10.7724c1.29517,0 2.37592,0.81106 2.74349,2.06042l24.43385,83.06614c2.14446,7.29116 8.88821,12.34011 16.49453,12.34011h62.93229c7.73092,0 14.55694,-5.22483 16.59532,-12.67604l14.89323,-54.61224c0.47113,-1.72445 0.11102,-3.56997 -0.97382,-4.99081c-1.08485,-1.42084 -2.7703,-2.25442 -4.55795,-2.25424h-22.93333h-28.66667v11.46667v26.29271l13.14636,-13.14636c2.24173,-2.24173 5.86556,-2.24173 8.10729,0c2.24173,2.24173 2.24173,5.86556 0,8.10729l-22.93333,22.93333c-1.118,1.118 -2.58591,1.67969 -4.05364,1.67969c-1.46773,0 -2.93564,-0.56169 -4.05364,-1.67969l-22.93333,-22.93333c-2.24173,-2.24173 -2.24173,-5.86556 0,-8.10729c2.24173,-2.24173 5.86556,-2.24173 8.10729,0l13.14636,13.14636v-26.29271v-5.73333v-5.73333h-48.65495l-7.08828,-24.10911c-0.00373,0 -0.00747,0 -0.0112,0c-1.78818,-6.07784 -7.40968,-10.29089 -13.73984,-10.29089zM91.73333,45.86667h11.46667v-28.66667c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333zM68.8,131.86667c-6.33287,0 -11.46667,5.1338 -11.46667,11.46667c0,6.33287 5.1338,11.46667 11.46667,11.46667c6.33287,0 11.46667,-5.1338 11.46667,-11.46667c0,-6.33287 -5.1338,-11.46667 -11.46667,-11.46667zM126.13333,131.86667c-6.33287,0 -11.46667,5.1338 -11.46667,11.46667c0,6.33287 5.1338,11.46667 11.46667,11.46667c6.33287,0 11.46667,-5.1338 11.46667,-11.46667c0,-6.33287 -5.1338,-11.46667 -11.46667,-11.46667z"></path>
      </g>
    </g>
  </Container>
);

export const Basket = ({ fill = "#000000", size = 24, className }) => (
  <Container fill={fill} size={size} className={className}>
    <g fill="none" fillRule="nonzero" stroke="none">
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill={fill}>
        <path d="M103.2,5.73333c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333c0,2.08347 1.16942,3.83358 2.83308,4.8375l-9.39505,15.65469c-3.21067,5.34347 -4.90469,11.47177 -4.90469,17.70391v1.93724h51.6v-1.93724c0,-6.23787 -1.69976,-12.36044 -4.90469,-17.70391l-9.39505,-15.65469c1.66365,-1.00392 2.83308,-2.75403 2.83308,-4.8375c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333zM71.66667,22.93333c-4.80453,0 -9.15166,1.75368 -12.60886,4.56875l16.86407,11.24271c1.08933,-4.44333 2.77744,-8.72577 5.15104,-12.67604l0.30234,-0.5263c-2.8896,-1.61107 -6.16539,-2.60911 -9.70859,-2.60911zM43.34714,31.46615c-2.45718,-0.3827 -5.01944,0.8118 -6.21484,3.2026l-8.46562,16.93125h45.86667l-28.81224,-19.21563c-0.74103,-0.49307 -1.5549,-0.79066 -2.37396,-0.91823zM17.2,63.06667c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333v11.46667c0,3.1648 2.56853,5.73333 5.73333,5.73333h0.95182l8.91354,53.48125c0.92307,5.52693 5.70843,9.58542 11.3099,9.58542h95.23828c5.6072,0 10.38683,-4.05848 11.30989,-9.58542l8.92474,-53.48125h0.95183c3.1648,0 5.73333,-2.56853 5.73333,-5.73333v-11.46667c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333zM63.06667,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333zM86,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333zM108.93333,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333z"></path>
      </g>
    </g>
  </Container>
);
