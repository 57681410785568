import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./Home";
import Signup from "./Signup";
import Thanks from "./Thanks";

export default () => (
  <Router>
    <Switch>
      <Route path="/signup">
        <Signup />
      </Route>
      <Route path="/thanks">
        <Thanks />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  </Router>
);
