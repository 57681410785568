import React from "react";

import Lottie from "react-lottie";
import animationData from "./search-icon-data.json";

export default ({ play, height, width }) => {
  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        isStopped={!play}
        isPaused={!play}
      />
    </div>
  );
};
