import React from "react";
import Container from "./Container";

export default () => (
  <Container>
    <h3 className="text-lg leading-6 font-medium text-gray-800 mb-4">
      Rimani aggiornato sui servizi a domicilio nella tua zona
    </h3>
    <p>
      Tutti i giorni aggiungiamo nuovi supermercati, commercianti e negozi di quartiere. Lasciaci il tuo indirizzo email
      e ti avviseremo ogni volta che ci saranno nuove soluzioni disponibili nella tua zona.
    </p>
    <script src="https://f.convertkit.com/ckjs/ck.5.js"></script>
    <form
      className="mt-5"
      action="https://app.convertkit.com/forms/1312966/subscriptions"
      method="post"
      data-sv-form="1312966"
      data-uid="e834cd94a4"
      data-version="5"
      data-options='{"settings":{"after_subscribe":{"action":"message","success_message":"Perfetto! Ti abbiamo mandato un&apos;email di conferma: controlla la tua posta in arrivo per confermare la tua iscrizione.","redirect_url":""},"analytics":{"google":null,"facebook":null,"segment":null,"pinterest":null},"modal":{"trigger":"timer","scroll_percentage":null,"timer":"10000","devices":"all","show_once_every":15},"powered_by":{"show":true,"url":"https://convertkit.com?utm_source=dynamic&amp;utm_medium=referral&amp;utm_campaign=poweredby&amp;utm_content=form"},"recaptcha":{"enabled":false},"return_visitor":{"action":"show","custom_content":""},"slide_in":{"display_in":"bottom_right","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"sticky_bar":{"display_in":"top","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15}},"version":"5"}'
      min-width="400 500 600 700 800"
    >
      <div className="flex flex-col sm:flex-row items-end w-full justify-between">
        <div className="flex-grow sm:flex-grow-0 w-full sm:mr-4">
          <label className="block text-sm font-medium leading-5 text-gray-700">Il tuo CAP</label>
          <input
            className="form-input w-full focus:border-green-300 focus:shadow-outline-green sm:text-sm sm:leading-5 mt-1"
            aria-label="CAP"
            name="fields[zip]"
            placeholder="00152"
            type="text"
          />
        </div>
        <div className="mt-5 sm:mt-0 flex-grow w-full sm:mr-4">
          <label className="block text-sm font-medium leading-5 text-gray-700">Il tuo indirizzo email</label>
          <input
            className="form-input block w-full focus:border-green-300 focus:shadow-outline-green sm:text-sm sm:leading-5 mt-1"
            name="email_address"
            placeholder="mario.rossi@gmail.com"
            required=""
            type="email"
          />
        </div>
        <button
          data-element="submit"
          className="mt-6 sm:mt-0 w-full sm:w-auto flex flex-grow-0 justify-center py-2 px-4 border-gray-300 bg-green-400 text-sm items-center font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
        >
          <svg className="-ml-0.5 mr-2 h-4 w-4" fill="#fff" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
              clipRule="evenodd"
            />
          </svg>
          <span className="text-white">Iscriviti</span>
        </button>
      </div>
      <p className="text-xs text-gray-400 mt-2">
        Rispettiamo la tua privacy, potrai disiscriverti in qualsiasi momento
      </p>
    </form>
  </Container>
);
