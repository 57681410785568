import React from "react";

export default ({ register, errors, onSubmit, loading }) => (
  <form onSubmit={onSubmit} className="px-4 py-5 sm:p-6">
    <label className="block text-sm font-medium leading-5 text-gray-700">Inserisci il tuo CAP</label>
    <div className="flex flex-col mt-1 items-start sm:flex-row ">
      <div className="w-full mb-4 sm:mb-0">
        <div className="rounded-md shadow-sm">
          <input
            ref={register({ pattern: /^[0-9]+$/i, maxLength: 5, minLength: 5 })}
            name="postcode"
            className={
              "form-input block w-full sm:text-sm sm:leading-5" +
              (errors.postcode
                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                : "focus:border-green-300 focus:shadow-outline-green")
            }
            placeholder="Esempio: 00195"
          />
          {errors.postcode && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
        </div>
        {errors.postcode && <p className="mt-2 text-sm text-red-600">Inserisci un CAP valido</p>}
      </div>
      <div className="sm:w-auto w-full sm:pl-4">
        <span className="w-full rounded-md shadow-sm">
          <button
            type="submit"
            className={
              (loading ? "spinner bg-gray-400 " : errors.postcode ? "bg-gray-400 " : "bg-orange-500 ") +
              "w-full flex items-center justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:border-green-700 focus:shadow-outline-green transition duration-150 ease-in-out"
            }
          >
            {!loading && (
              <svg viewBox="0 0 24 24" className="h-4 mr-2" fill="#fff">
                <path
                  className="heroicon-ui"
                  d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
                />
              </svg>
            )}
            <span className="whitespace-no-wrap">Cerca spesa a domicilio</span>
          </button>
        </span>
      </div>
    </div>
  </form>
);
