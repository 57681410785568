import React, { useEffect } from "react";
import { Truck, Cart } from "./Icons";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from "simple-react-lightbox";

const diffDays = (date1, date2) => parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10) + 1;

const sameDay = (parsedDate) => {
  const today = new Date();

  return (
    parsedDate instanceof Date && parsedDate.getDate() == today.getDate() && parsedDate.getMonth() == today.getMonth()
  );
};

export default ({
  id,
  name,
  deliveryDate,
  url,
  logo,
  tags,
  minAmount,
  shipment,
  address,
  inventoryScore,
  phone,
  photos,
  description,
  orderModes,
  zipcode,
}) => {
  const parsedDate = new Date(Date.parse(deliveryDate));
  const dateDistance = diffDays(new Date(), parsedDate);
  const todayDelivery = sameDay(parsedDate);

  const statusColor = dateDistance < 4 ? "green-600" : dateDistance < 10 ? "yellow-400" : "red-600";

  useEffect(() => {
    window.analytics.track("Viewed Supplier", {
      supplierId: id,
      supplierName: name,
      zipcode: zipcode,
    });
  }, [id, name, zipcode]);

  return (
    <div className={`mt-5 w-full ${!deliveryDate && "opacity-50"}`}>
      <div className="bg-white overflow-hidden shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6 border-b border-gray-200">
          <div className="flex items-center">
            <div className="w-0 flex-1">
              <div>
                <div className="text-sm leading-5 font-medium text-gray-500 truncate">
                  {name} {address && " – " + address}
                </div>
                <div className="flex items-baseline mb-2">
                  <div className="text-xl sm:text-2xl leading-8 font-semibold text-gray-900">
                    {todayDelivery ? (
                      <span className="text-blue-600">Consegna oggi</span>
                    ) : deliveryDate ? (
                      parsedDate.toLocaleDateString("it-IT", {
                        day: "numeric",
                        month: "long",
                      })
                    ) : (
                      "Non disponibile"
                    )}
                  </div>
                  <div className={`ml-2 flex items-baseline text-sm leading-5 font-semibold text-${statusColor}`}>
                    {!todayDelivery && deliveryDate && `in ${dateDistance} giorn${dateDistance > 1 ? "i" : "o"}`}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-shrink-0">{logo && <img className="h-6 sm:h-10" alt={name} src={logo} />}</div>
          </div>
          <div className="flex flex-col">
            {description && (
              <div className="max-w-md">
                <p className="text-xs mb-4 text-gray-500">{description}</p>
              </div>
            )}
            <div className="flex flex-row items-center mb-1">
              <Truck fill="#6B727F" size={18} className="pr-2" />
              <span className="text-xs text-gray-500">
                <span>Spedizione: </span> {shipment}
              </span>
            </div>
            <div className="flex flex-row items-center mb-1">
              <Cart fill="#6B727F" size={18} className="pr-2" />
              <span className="text-xs text-gray-500">
                <span>Spesa minima: </span> {minAmount}
              </span>
            </div>
          </div>
        </div>
        {photos && photos.length > 0 && (
          <div className="px-4 py-5 sm:p-6 border-b border-gray-200">
            <SimpleReactLightbox>
              <SRLWrapper>
                <div className="flex">
                  {photos.map((p, i) => (
                    <img className="h-12 mr-2 rounded-sm shadow hover:opacity-75" src={p} key={`${name}-${i}`} />
                  ))}
                </div>
              </SRLWrapper>
            </SimpleReactLightbox>
          </div>
        )}
        <div className="bg-gray-50 px-4 py-4 sm:px-6 flex flex-row items-center text-xs sm:text-sm leading-5">
          {url && (
            <div
              onClick={() => {
                window.analytics.track("Exit on Supplier", {
                  supplierId: id,
                  supplierName: name,
                  type: "website",
                  website: url,
                  zipcode: zipcode,
                });
                window.open(url);
              }}
              className="flex mr-5 font-medium items-center text-teal-600 hover:underline transition ease-in-out duration-150"
            >
              <svg className="mr-1 sm:mr-2 sm:h-5 h-4" fill="#247481" viewBox="0 0 24 24">
                <path
                  className="heroicon-ui"
                  d="M13 17h-2v2h2v-2zm2 0v2h2a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2h2v-2H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-5zM4 5v10h16V5H4z"
                />
              </svg>
              Ordina sul sito
            </div>
          )}
          {phone && orderModes.includes("WhatsApp") && (
            <div
              className="flex font-medium items-center text-teal-600 hover:underline transition ease-in-out duration-150"
              onClick={() => {
                window.analytics.track("Exit on Supplier", {
                  supplierId: id,
                  supplierName: name,
                  type: "whatsapp",
                  phone: phone,
                  zipcode: zipcode,
                });
                window.open(
                  `https://api.whatsapp.com/send?phone=39${phone}&source=trovaspesa&text=Salve,%20posso%20ordinare%20a%20domicilio?%20Vi%20ho%20trovato%20sul%20sito%20trovaspesa.info`
                );
              }}
            >
              <svg className="mr-1 sm:mr-2 sm:h-5 h-4" fill="#247481" viewBox="0 0 24 24">
                <path
                  className="heroicon-ui"
                  d="M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4z"
                />
              </svg>
              Ordina su WhatsApp
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
