import React, { useState } from "react";
import { assoc, mapObjIndexed, all } from "ramda";
import Supplier from "./Supplier";
import Container from "./Container";
import Animated from "./Animated";

export default ({ suppliers, categories, postcode, loading }) => {
  const [selectedCategories, setFilteredCategories] = useState(categories);

  const [visibleFilters, toggleVisibleFilters] = useState(false);

  const toggleCategory = (category) => {
    const cat = selectedCategories[category];

    if (cat) {
      const newCategories = assoc(category, { ...cat, selected: !cat.selected }, selectedCategories);
      setFilteredCategories(newCategories);
    }
  };

  const arrayCategories = Object.values(mapObjIndexed((n, k, v) => ({ key: k, ...v[k] }), selectedCategories));
  const mandatoryCategories = arrayCategories.filter((c) => selectedCategories[c.key].selected);
  const goodSuppliers = suppliers.filter((s) => all((c) => s.itemsCategories.includes(c.key), mandatoryCategories));

  return goodSuppliers.length > 0 ? (
    <div className="mb-10 mt-10">
      <div className="flex flex-col sm:flex-row items-center justify-between">
        <h3 className="justify-center text-lg px-4 sm:px-0 font-medium text-center text-gray-600">
          {`Servizi che spediscono nella tua zona – ${postcode}`}
        </h3>
        <span className="inline-flex px-4 sm:px-0 rounded-md shadow-sm mt-4 sm:mt-0 w-full sm:w-auto">
          <button
            type="button"
            onClick={() => toggleVisibleFilters(!visibleFilters)}
            className="w-full justify-center inline-flex items-center px-4 py-2 border border-orange-500 text-sm leading-5 font-medium rounded-md text-orange-500 bg-white focus:outline-none focus:border-orange-300 focus:shadow-outline-orange active:text-orange-800 active:bg-orange-50 transition ease-in-out duration-150"
          >
            <svg viewBox="0 0 24 24" className="-ml-0.5 mr-2 h-4 w-4">
              <path
                className="heroicon-ui"
                fill="#F45A21"
                d="M2.3 7.7A1 1 0 0 1 2 7V3a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v4a1 1 0 0 1-.3.7L15 14.42V17a1 1 0 0 1-.3.7l-4 4A1 1 0 0 1 9 21v-6.59l-6.7-6.7zM4 4v2.59l6.7 6.7a1 1 0 0 1 .3.71v4.59l2-2V14a1 1 0 0 1 .3-.7L20 6.58V4H4z"
              />
            </svg>
            {visibleFilters ? "Nascondi filtri" : "Filtra risultati"}
          </button>
        </span>
      </div>
      {visibleFilters && (
        <Container className="mt-5">
          <p className="mb-2 text-sm text-gray-500">
            Seleziona il tipo di prodotti che vuoi comprare e scopri chi può consegnarti tutto
          </p>
          <div className="flex flex-row flex-wrap -mr-3">
            {Object.values(selectedCategories).map((c, i) => {
              return !c.selected ? (
                <button
                  key={i}
                  onClick={() => toggleCategory(Object.keys(selectedCategories)[i])}
                  className="focus:outline-none transition duration-200 ease-in-out transform shadow mr-2.5 mt-2.5 inline-flex items-center pl-2.5 pr-1.5 py-1 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800"
                >
                  {c.label}
                  <div className="font-large flex-shrink-0 ml-1.5 inline-flex text-green-500 focus:outline-none focus:text-green-700">
                    <svg className="h-4 w-4" stroke="#6A7180" fill="#6A7180" viewBox="0 0 24 24">
                      <path
                        className="heroicon-ui"
                        strokeWidth="1"
                        d="M17 11a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4h4z"
                      />
                    </svg>
                  </div>
                </button>
              ) : (
                <button
                  key={i}
                  onClick={() => toggleCategory(Object.keys(selectedCategories)[i])}
                  className="focus:outline-none transition duration-200 ease-in-out transforms shadow mr-2.5 mt-2.5 inline-flex items-center pl-2.5 pr-1.5 py-1 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800"
                >
                  {c.label}
                  <div className="font-large flex-shrink-0 ml-1.5 inline-flex text-green-500 focus:outline-none focus:text-green-700">
                    <svg className="h-4 w-4" stroke="currentColor" fill="currentColor" viewBox="0 0 24 24">
                      <path
                        className="heroicon-ui"
                        d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"
                      />
                    </svg>
                  </div>
                </button>
              );
            })}
          </div>
        </Container>
      )}
      {goodSuppliers.map((supplier, i) => (
        <Animated key={i} play={!!supplier}>
          <Supplier {...supplier} zipcode={postcode} />
        </Animated>
      ))}
    </div>
  ) : (
    postcode && !loading && (
      <div className="mb-10">
        <h3 className="flex justify-center text-lg px-4 font-medium text-center text-gray-600">
          Mi dispiace! Non ci sono servizi disponibili nella tua zona
        </h3>
        <p className="text-center text-gray-500">Sei sicuro di aver inserito il CAP correttamente?</p>
      </div>
    )
  );
};
