import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import SearchIcon from "./SearchIcon";
import ListSuppliersForm from "./ListSuppliersForm";
import Navbar from "./Navbar";
import Animated from "./Animated";
import ContactUs from "./ContactUs";
import SupplierRegister from "./SupplierRegister";
import About from "./About";
import Results from "./Results";

const categories = {
  carne: { label: "🥩 Carne", selected: false },
  "frutta-verdura": { label: "🍎 Frutta e verdura", selected: false },
  latte: { label: "🥛 Latte", selected: false },
  conserve: { label: "🥫 Conserve", selected: false },
  pane: { label: "🍞 Pane", selected: false },
  pasta: { label: "🍝 Pasta", selected: false },
  uova: { label: "🥚 Uova", selected: false },
  dolci: { label: "🍰 Dolci", selected: false },
  surgelati: { label: "❄️ Surgelati", selected: false },
  "cibi-pronti": { label: "🥘 Cibi pronti", selected: false },
  bere: { label: "🍷 Vino e drinks", selected: false },
  animali: { label: "🐈 Per animali", selected: false },
  "casa-igiene": { label: "🏠 Casa e igiene", selected: false },
  "spesa-grande": { label: "🛒 Spesa grande", selected: false },
};

const BASE_URL = "https://r3c0h835a4.execute-api.eu-west-1.amazonaws.com/dev/";

const Home = () => {
  const [postcode, setPostcode] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { register, errors, handleSubmit } = useForm();

  const onSubmit = ({ postcode }) => {
    window.analytics.track("Created Search", {
      zipcode: postcode,
    });
    setPostcode(postcode);
  };

  useEffect(() => {
    if (postcode) {
      setLoading(true);
      axios
        .get(BASE_URL + "suppliers?zipcode=" + postcode)
        .then((response) => {
          setLoading(false);
          setSuppliers(response.data);
        })
        .catch((error) => {
          setSuppliers({ error: "Al momento non ci sono servizi disponibili" });
          setLoading(false);
        });
    }
  }, [postcode]);

  const hasNoResults = suppliers.length == 0 || loading;

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div>
        <Navbar />
        <div className={(hasNoResults ? "sm:py-6" : "") + " max-w-2xl mx-auto sm:px-6"}>
          <Animated play={true}>
            <div
              className={(hasNoResults ? "sm:rounded-lg" : "sm:rounded-b-lg") + " bg-white overflow-hidden shadow mb-6"}
            >
              {hasNoResults && <Header className="flex flex-row" loading={loading} />}
              <ListSuppliersForm
                register={register}
                errors={errors}
                onSubmit={handleSubmit(onSubmit)}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
            <Results categories={categories} suppliers={suppliers} postcode={postcode} loading={loading} />
            {hasNoResults && <About />}
            <ContactUs />
            <SupplierRegister />
          </Animated>
        </div>
      </div>
      <div>
        <div className="flex flex-row mx-auto mb-6 w-full justify-center text-sm mt-20 items-center opacity-50">
          <span>
            Creato da{" "}
            <a
              className="text-green-600"
              rel="noopener noreferrer"
              target="_blank"
              href="https://twitter.com/lucaronin"
            >
              Luca
            </a>{" "}
            con ♥︎ durante la quarantena
          </span>
        </div>
      </div>
    </div>
  );
};

const Header = ({ loading }) => (
  <div className="flex flex-row px-4 py-5 border-b border-gray-200 sm:px-6">
    <SearchIcon width={50} height={50} play={loading} />
    <div className="ml-3">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Confronta i servizi di spesa a domicilio su Roma e provincia
      </h3>
      <p className="mt-1 text-sm leading-5 text-gray-500">
        Scopri quando possono consegnarti la spesa in base alla loro disponibilità
      </p>
    </div>
  </div>
);

export default Home;
